<template>
  <div class="main-wrap">
    <page-bread />
    <el-container class="home-container">
      <el-aside width="292px">
        <page-aside>
          <el-menu :default-active="activeIndex" router>
            <el-menu-item
              :index="item.path"
              v-for="item in menuList"
              :key="item.path"
              >{{ item.name }}</el-menu-item
            >
          </el-menu>
        </page-aside>
      </el-aside>
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import pageAside from "@/components/pageAside.vue";
export default {
  name: "about",
  data() {
    return {
      activeIndex: this.$route.path,
      menuList: [
        {
          name: "基金会介绍",
          path: "/about/introduce",
        },
        {
          name: "基金会章程",
          path: "/about/constitution",
        },
        {
          name: "组织机构",
          path: "/about/structure",
        },
        {
          name: "理事会",
          path: "/about/council",
        },
        {
          name: "理事长寄语",
          path: "/about/chairman",
        },
        {
          name: "联系我们",
          path: "/about/contact",
        },
      ],
    };
  },
  methods: {},
  components: {
    pageAside,
  },
  watch: {
    "$route.path"(val) {
      this.activeIndex = val;
    },
  },
};
</script>

<style lang='scss' scoped>
.el-main {
  border-left: 8px solid #f4f4f4;
}
::v-deep .el-main {
  padding: 0;
}
</style>